import { useContext, useState } from "react";
import {Context as CountryContext} from "../context/CountryContext";
import { formatIsoAsUkDate } from "../helpers/helpers";
import EditItineraryOrderDialog from "./dialogs/EditItineraryOrderDialog";
import AreYouSureDialog from "./dialogs/AreYouSureDialog";
import { deleteItineraryFromStorage } from "../localstorage/store";
import { useNavigate } from "react-router-dom";

const ItineraryItemsPanel = () => {
  const {state:{selectedItinerary,itineraries},setItineraries,setSelectedItinerary,setSelectedLocation} = useContext(CountryContext);
  const [showEditItinerary,setShowEditItinerary] = useState(false);
  const [showAreYouSure,setShowAreYouSure] = useState(false);
  const navigate = useNavigate();

  const handleDeleteItinerary = () => {
    //Delete from context
    const newItineraries = itineraries.filter(i => i.id !== selectedItinerary.itinerary.id);
    setItineraries(newItineraries);
    deleteItineraryFromStorage(selectedItinerary.itinerary.id);
    //setSelectedItinerary(null);
    if(newItineraries.length > 0) {
      setSelectedItinerary(newItineraries[0]);
    }  else {
      //Navigate to the home page
      setSelectedItinerary(null);
      navigate('/');
    }
  }

  const handelNavLocation = (location)   => {
    setSelectedLocation(location);
    if(location.country) {
      navigate(`/viewcountry/${location.country}`);
    } 
  }

  return(
    <div className="itinerary-items-panel">
      {
        selectedItinerary && itineraries.length > 0
        ? 
          selectedItinerary.itinerary.locations.length > 0
          ? <>
            {
              showEditItinerary
              ? <EditItineraryOrderDialog onDismiss={() => setShowEditItinerary(false)} />
              : <></>
            }
            {
              showAreYouSure
              ? <AreYouSureDialog onDismiss={() => setShowAreYouSure(false)} title="Delete Itinerary" message={`Are you sure you want to delete the itinerary ${selectedItinerary.itinerary.name}?`} handleYesAction={() => handleDeleteItinerary()} />
              : <></>
            }
            <p>Here are the locations you are visiting:</p>
              <div className="itinerary-items-container">
                <div className="itinerary-items-list">
                  {
                    selectedItinerary.itinerary.locations.map((location, idx) => (
                      <div key={location.id} className="location-item">
                        <p>{idx + 1}.</p>
                        <p className="link" onClick={() => handelNavLocation(location)}>{ location.name }</p>
                        <p>{ formatIsoAsUkDate(location.startDate) }</p>
                        <p>{ formatIsoAsUkDate(location.endDate) }</p>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="button-bar-horizontal">
                <button className="btn" onClick={() => setShowEditItinerary(true)}>
                  Edit Itinerary
                </button>
                <button className="btn" onClick={() => setShowAreYouSure(true)}>
                  Delete Itinerary
                </button>
              </div>
          </>
          : <p>No locations exist on this itinerary.</p>
        

        : <p>No itinerary is selected.</p>
      }

    </div>

  );

}

export default ItineraryItemsPanel;
