const Modal = (props) => {

  const closeModal = () => {
    const backdrop = document.querySelector(".backdrop");
    const modal = document.querySelector(".modal");

    backdrop.classList.add("hidden");
    modal.classList.add("hidden");
  }

  return(
      <>
          <div className="backdrop" onClick={() => props.onDismiss(true)} ></div>
      
          <div className={`modal ${props.additionalClassName}`}>
              <div className="header">
                  <h1>{props.title}</h1>
              </div>
              <div className="body">
                { props.children }
              </div>
              <div className="footer">
              </div>
          </div>
      </>

  )
}

export default Modal;