import { useContext, useState, useEffect } from "react";
import {Context as CountryContext } from "../context/CountryContext";

const ItinerarySelect = () => {
  const {state:{itineraries, selectedItinerary}, setSelectedItinerary} = useContext(CountryContext);
  const [selectedValue, setSelectedValue] = useState(selectedItinerary);
  
  useEffect(() => {
    if(selectedItinerary) {
      setSelectedValue(selectedItinerary.itinerary.id);
    }
    
  },[selectedItinerary]);

  const handleChangeItinerary = (evt) => {
    const newItinerary = itineraries.filter(itinerary => itinerary.id === evt.currentTarget.value);
    setSelectedValue(evt.currentTarget.value);
    setSelectedItinerary(newItinerary[0]);
  }

  return(
    itineraries.length > 0 && selectedItinerary
    ?
    <select className="itinerary-select" onChange={(evt) => handleChangeItinerary(evt)} value={selectedValue}>
      {
        itineraries.map((itinerary) => (
          <option key={itinerary.id} value={itinerary.id}>
            {itinerary.name}
          </option>
        ))
      }
    </select>
    : <p>You don't have any itineraries</p>
  );
}

export default ItinerarySelect;