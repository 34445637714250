//Prompts the user if they want to remove all of their locations from the itinerary and saves the result
import Modal from "./Modal";
import {Context as CountryContext} from "../../context/CountryContext";
import { useContext } from "react";
import { addToItinerary, getItineraries } from "../../localstorage/store";

const AreYouSureDialog = (props) => {

  return(
    <Modal text="" title={props.title} onDismiss={props.onDismiss} >
      <p>{props.message}</p>
      <div className="button-bar-horizontal">
        <button className="btn" onClick={() => props.handleYesAction()}>Yes</button>
        <button className="No" onClick={() => props.onDismiss()} >No</button>
      </div>
    </Modal>
  );
}

export default AreYouSureDialog;