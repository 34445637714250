import { useContext, useRef, useState, useEffect } from "react";
import Modal from "./Modal";
import {Context as CountryContext } from "../../context/CountryContext";
import { formatIsoAsUkDate, addDaysToDate, getDaysBetweenDates, reOrganiseLocations } from "../../helpers/helpers";
import DatePicker from "../DatePicker";
import { addToItinerary, getItineraries } from "../../localstorage/store";

const EditStartDateDialog = (props) => {
  const { state:{selectedItinerary}, setSelectedItinerary, setItineraries } = useContext(CountryContext);
  const errorRef = useRef(null);
  const [startDate,setStartDate] = useState(null);

  useEffect(() => {
  },[]);


  const validateFormData = (startDate) => {
    const errorDisplay = errorRef.current;

    //Check all fields have values
    if(startDate) {
      const endDate = new Date(selectedItinerary.itinerary.endDate);
      //Check that the start date is before the enddate
      if(startDate < endDate) {
        return true;
      } else {
        errorDisplay.innerHTML = "The start date must be before the end date.";
      }
      return false;
    }
  } 

  const handleUpdateStartDate = (evt) => {
    if(validateFormData(startDate)) {
      const newItinerary = {...selectedItinerary};
      const newLocations = [...selectedItinerary.itinerary.locations];
      newLocations[0].startDate = startDate.toISOString();
      const reOrganizedLocations = reOrganiseLocations(newLocations,startDate.toISOString());
      newItinerary.itinerary.startDate = startDate.toISOString();
      newItinerary.itinerary.locations = reOrganizedLocations;
      //Update the new reordered itinerary everywhere
      setSelectedItinerary(newItinerary.itinerary);
      addToItinerary(selectedItinerary.itinerary, selectedItinerary.itinerary.id);
      //Set as the current itinerary
      const newItineraries = getItineraries();
      setItineraries(newItineraries);
      props.onDismiss();
    }
  }

  return(
    <Modal text="" title="Change Start Date" additionalClassName="change-start-date" onDismiss={props.onDismiss}>
      <div className="dialog">
        <div className="error-panel">
          <p ref={errorRef} className="error"></p>
        </div>
        <div className="form-group">
                  <label htmlFor="itinerary-end-date">Start Date:</label>
                  <DatePicker onDateSelected={setStartDate} id="itinerary-end-date" date={ selectedItinerary.itinerary.startDate} />
        </div>
        <button className="btn" onClick={(evt) => handleUpdateStartDate(evt)}>Update</button>
      </div>
    </Modal>
  );
}

export default EditStartDateDialog;