import createDataContext from './createDataContext';
import { getCountries } from '../network/apiActions';
import { getItineraries } from '../localstorage/store';

const defaultState = {
  countries : {},
  selectedCountry : null,
  locations : {},
  selectedLocation : null,
  countryMapMessage : "Click to get location information",
  selectedItinerary : null,
  itineraries : [],
};

const countryReducer = (state,action) => {
  switch(action.type) {
    case 'setCountries':
      return {...state,countries:action.payload};
    case 'setSelectedCountry':
      return {...state,selectedCountry:action.payload};
    case 'setLocations':
      return {...state,locations:action.payload};
    case 'setSelectedLocation':
      return {...state,selectedLocation:action.payload};
    case 'setCountryMapMessage':
      return {...state,countryMapMessage:action.payload};
    case 'setSelectedItinerary':
      return {...state,selectedItinerary:action.payload};
    case 'setItineraries':
      return {...state,itineraries:action.payload};
    case 'addLocation':
      const locations = {...state.locations};
      const country = action.payload.country;
      const newLocations = locations[country];
      newLocations[action.payload.location.name] = action.payload.location;
      locations[country] = newLocations;
      return {...state,locations:locations};
    default:
      return defaultState;
  }
};

//Setters

const setCountries = (dispatch) => (countriesList) => {
  dispatch({type:'setCountries', payload:countriesList});
}

const setSelectedCountry = (dispatch) => (country) => {
  dispatch({type:'setSelectedCountry', payload:country});
}

const setLocations = (dispatch) => (locationsList) => {
  dispatch({type:'setLocations', payload:locationsList});
}

const setSelectedLocation = (dispatch) => (location) => {
  dispatch({type:'setSelectedLocation', payload:location});
}

const setCountryMapMessage = (dispatch) => (message) => {
  dispatch({type:'setCountryMapMessage', payload:message});
}

const addLocation = (dispatch) => (location,country) => {
  dispatch({type:'addLocation', payload:{location,country}});
}

const setSelectedItinerary = (dispatch) => (itinerary) => {
  dispatch({type:'setSelectedItinerary', payload:{itinerary}});
}

const setItineraries = (dispatch) => (itineraries) => {
  dispatch({type:'setItineraries', payload:itineraries})
}


export const {Provider, Context} = createDataContext (
    countryReducer,
    { setCountries, setSelectedCountry, setLocations,setSelectedLocation,setCountryMapMessage, 
      addLocation, setSelectedItinerary, setItineraries
    },
    {...defaultState}
);