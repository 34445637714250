import React from 'react';
import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet';
import * as turf from '@turf/turf';
import countryData from './custom.geo.json'; // Your GeoJSON data
import 'leaflet/dist/leaflet.css';
import { useContext } from "react";
import {Context as CountryContext} from "../context/CountryContext";

const WorldMap = () => {
  const {setSelectedCountry,state:{countries}} = useContext(CountryContext);


  // London coordinates
  const londonPosition = [51.5074, -0.1278];

  // Component to handle map click events
  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        const { lat, lng } = e.latlng;
        // Reverse geocode to find the country
        const countryName = getCountryFromCoordinates(lat, lng);
        if(Object.keys(countries).includes(countryName)) {
          setSelectedCountry(countries[countryName]);
        } else {
          setSelectedCountry(null);
        }
      },
    });
    return null;
  };

  const getCountryFromCoordinates = (lat, lng) => {
    const point = turf.point([lng, lat]);

    for (let country of countryData.features) {
      const polygon = turf.feature(country.geometry);

      // Use Turf's built-in booleanPointInPolygon to check
      if (turf.booleanPointInPolygon(point, polygon)) {
        const countryName = country.properties.brk_name;
        return countryName;
      }
    }
  };

  return (
    <div className='world-map'>
      <MapContainer center={londonPosition} zoom={5} style={{ height: "100%", width: "100%" }}>
        {/* TileLayer is for the actual map tiles */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* Handle map click events */}
        <MapClickHandler />
      </MapContainer>
    </div>
  );
};

export default WorldMap;
