import * as turf from '@turf/turf';
import countryData from './custom.geo.json'; // Your GeoJSON data

const getCountryFromCoordinates = (lat, lng) => {
  const point = turf.point([lng, lat]);

  for (let country of countryData.features) {
    const polygon = turf.feature(country.geometry);

    // Use Turf's built-in booleanPointInPolygon to check
    if (turf.booleanPointInPolygon(point, polygon)) {
      const countryName = country.properties.brk_name;
      return countryName;
    }
  }
};

const degToRad = (degrees) => {
  return degrees * (Math.PI / 180);
}

const calculateHaversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Earth radius in meters
  const dLat = degToRad(lat2 - lat1);
  const dLon = degToRad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(degToRad(lat1)) * Math.cos(degToRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in meters
  return distance;
}

//Get the nearest location from an object which has the location name as the key with the distance
//If there are no location keys then return null
const getClosestLocation = (locationDistance) => {
  const locations = Object.keys(locationDistance);
  let smallestKey = null;
  if(locations.length > 0) {
    smallestKey = locations.reduce((smallest, key) => {
      return locationDistance[key] < locationDistance[smallest] ? key : smallest;
    }, locations[0]);
  }
  return smallestKey
}

export {getCountryFromCoordinates, calculateHaversineDistance, getClosestLocation};