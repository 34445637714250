import {conn, connWithAuth} from './api';


const getCountries = async () => {
  const url = '/api/getcountries?include_attractions=true';
  return new Promise((resolve,reject) => {
    conn.get(url)
    .then( (response) => {
      return resolve(response.data);
    }).catch((err) => {
      console.log("ERROR",err);
      return reject(err);
    });
  });
}

const getLocations = async (country) => {
  const url = `/api/getlocationsforcountry?country=${country}`;
  return new Promise((resolve,reject) => {
    conn.get(url)
    .then( (response) => {
      return resolve(response.data);
    }).catch((err) => {
      console.log("ERROR",err);
      return reject(err);
    });
  });
}

const getLocationFromCoords = async (lat,lng) => {
  const url = `/api/getlocationinfo?lat=${lat}&lng=${lng}`;
  return new Promise((resolve,reject) => {
    conn.get(url)
    .then( (response) => {
      return resolve(response.data);
    }).catch((err) => {
      console.log("ERROR",err);
      return reject(err);
    });
  });
}

export {getCountries, getLocations, getLocationFromCoords};