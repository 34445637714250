import { useContext, useEffect, useState } from 'react';
import {Context as CountryContext} from '../context/CountryContext';
import { useParams } from 'react-router-dom';
import { loadCountries, loadLocationsForCountry } from '../localstorage/store';
import LoadingWidget from '../components/LoadingWidget';
import NetworkErrorWidget from '../components/NetworkErrorWidget';
import CountryMap from '../components/CountryMap';
import LocationInfoPanel from '../components/LocationInfoPanel';
import CountryTitleBanner from '../components/CountryTitleBanner';
import Footer from '../layout/Footer';


const CouuntryScreen = () => {
  const { country } = useParams();
  const [loading,setLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const {state:{countries,selectedCountry, selectedLocation},setSelectedCountry,setCountries,setLocations} = useContext(CountryContext);

  useEffect(() => {
    //Load the countries if they are not present
    if(!selectedCountry) {
      loadCountries().then((countryMap) => {
        setCountries(countryMap);
        setSelectedCountry(countryMap[country]);
        loadLocationsForCountry(country).then((locations) => {
          setLocations(locations);
          setLoading(false);
        });
      }).catch(err => {
        setNetworkError(true);

      });
    } else {
      loadLocationsForCountry(country).then((locations) => {
        setLocations(locations);
        setLoading(false);
      }).catch(err => {
        setNetworkError(true);
      });
      setLoading(false);
    }
  },[]);

  return (
    <div className='content'>
      {
        !networkError
        ? <> {
          !loading
          ? <>
            <CountryTitleBanner />
            <p className='country-description' >{selectedCountry.description}</p>
            <div className='location-panel display-panel'>
              {
                selectedLocation
                ? <h1 className='alt-display'>{selectedLocation.name}</h1>
                : <h1 className='alt-display'>Select a Location</h1>
              }
              <CountryMap country={country} />
              <LocationInfoPanel country={country}/>
            </div>
            <Footer />
          </>
          : <LoadingWidget/>
        } </>
        : <NetworkErrorWidget />
      }

    </div>
  );
}

export default CouuntryScreen;