import './App.css';
import { useEffect, useState, useContext } from 'react';
import {Context as CountryContext} from './context/CountryContext';
import Main from './Main';
import LoadingWidget from './components/LoadingWidget';
import { getItineraries } from './localstorage/store';

function App() {
  const [loaded,setLoaded] = useState(false);
  const {setSelectedItinerary,setItineraries} = useContext(CountryContext);

  useEffect(() => {
    //Load the itineraries and select the first one as default
    const itineraries = getItineraries();
    if(itineraries.length > 0) {
      setSelectedItinerary(itineraries[0]);
    }
    setItineraries(itineraries);
    setLoaded(true);
  },[]);

  return (
    <div className="App">
      {/* <DatePicker /> */}
      {
        loaded
        ? <Main />
        : <LoadingWidget />
      }
    </div>
  );
}

export default App;
