import { useState, useRef, useContext } from "react";
import Modal from "./Modal";
import DatePicker from "../DatePicker";
import { Context as CountryContext} from "../../context/CountryContext";
import { addToItinerary, getItineraries } from "../../localstorage/store";
import { v4 as uuidv4 } from 'uuid';
import { getDaysBetweenDates } from "../../helpers/helpers";



const CreateItineraryDialog = (props) => {
  const [startDate,setStartDate] = useState(null);
  const [endDate,setEndDate] = useState(null);
  const [itineraryName, setItineraryName] = useState(null);
  const [numberOfDays,setNumberOfDays] = useState(1);
  const errorRef = useRef(null);
  const {setSelectedItinerary, setItineraries} = useContext(CountryContext);


  const validateFormData = (startDate,itineraryName) => {
    const errorDisplay = errorRef.current;

    //Check all fields have values
    if(startDate && itineraryName) {
        //Check that the itinerary name has a value
        if(itineraryName.trim().length > 0) {
          //Check number of days fits in the date range
          return true;
        } else {
          errorDisplay.innerHTML = "Please enter a value for the itinerary name.";
        }
    } else {
      errorDisplay.innerHTML = "Name and start date need to be entered.";
    }

    return false;
  } 

  const handleCreateItinerary = (evt) => {
    evt.preventDefault();
    if(validateFormData(startDate,itineraryName)) {
      
      const uuid = uuidv4();
      const itineraryObject = {
        id : uuid,
        name : itineraryName,
        startDate : startDate.toISOString(),
        endDate : startDate.toISOString(),
        locations : [ ],
      }
      addToItinerary(itineraryObject,uuid);
      //Set as the current itinerary
      setSelectedItinerary(itineraryObject);
      const newItineraries = getItineraries();
      setItineraries(newItineraries);
      props.onDismiss(false);
    }
    
  }

  const handleNameInput = (evt) => {
    setItineraryName(evt.currentTarget.value);
  } 

  return (
    <Modal text="" title="Create New Itinerary" additionalClassName="add-itinerary" onDismiss={props.onDismiss}>
      <p>Enter the name of the itinerary and the date you start travel.</p>
      <div className="dialog">
        <div className="error-panel">
          <p ref={errorRef} className="error"></p>
        </div>
        <div className="form-group">
          <label htmlFor="itinerary-name-input">Name of Itinerary:</label>
          <input onInput={(evt) => handleNameInput(evt)} id="itinerary-name-input" type="text"></input>
        </div>
        <div className="form-group">
          <label htmlFor="itinerary-start-date">Start Date:</label>
          <DatePicker onDateSelected={setStartDate} id="itinerary-start-date" />
        </div>
        {/* <div className="form-group">
          <label htmlFor="itinerary-end-date">End Date:</label>
          <DatePicker onDateSelected={setEndDate} id="itinerary-end-date"  />
        </div> */}
      </div>
      <button onClick={(evt) => handleCreateItinerary(evt)}>Create</button>
    </Modal>
  )
}

export default CreateItineraryDialog;
