import { useContext, useRef, useState, useEffect } from "react";
import Modal from "./Modal";
import {Context as CountryContext } from "../../context/CountryContext";
import { formatIsoAsUkDate, addDaysToDate, getDaysBetweenDates } from "../../helpers/helpers";
import DatePicker from "../DatePicker";
import { addToItinerary } from "../../localstorage/store";

const EditItineraryDialog = (props) => {
  const { state:{selectedItinerary}, setSelectedItinerary } = useContext(CountryContext);
  const errorRef = useRef(null);
  const [endDate,setEndDate] = useState(null);
  //const [numberOfDays,setNumberOfDays] = useState(1);
  const [existsInItinerary, setExistsInItinerary] = useState(true);
  const [continuationDate,setContinuationDate] = useState("");
  const numberOfDays = 1;

  useEffect(() => {
    const existingLocation = selectedItinerary.itinerary.locations.filter((el) => el.id === props.location.id);

    if(existingLocation.length > 0) {
      setExistsInItinerary(true);
    } else {
      setExistsInItinerary(false);
    }

    //Get the start date which is the finish date of the previous item
    let continuationDate = selectedItinerary.itinerary.endDate;
    //If there are locations then the contunuation date needs to be obtained from the last location
    if(selectedItinerary.itinerary.locations.length > 0) {
      continuationDate = selectedItinerary.itinerary.locations[selectedItinerary.itinerary.locations.length-1].endDate;
    }
  
    setContinuationDate(continuationDate);
  },[]);


  const validateFormData = (startDate,endDate) => {
    const errorDisplay = errorRef.current;

    //Check all fields have values
    if(startDate && endDate) {
      //Check that the start date is before the enddate
      if(startDate < endDate) {
         //Check number of days fits in the date range
        const daysBetween = getDaysBetweenDates(startDate,endDate);
        if(daysBetween >= numberOfDays) {
          return true;
        } else {
          errorDisplay.innerHTML = "The number of days is more than the number of days left in the itinerary.";
        }
      } else {
        errorDisplay.innerHTML = "The start date must be before the end date.";
      }
    } else {
      errorDisplay.innerHTML = "Name, start date and end date need to be entered.";
    }

    return false;
  } 

  const handleAddToItinerary = (evt) => {
    evt.preventDefault();
    if(validateFormData(new Date(continuationDate),endDate)) {
      const daysBetween = getDaysBetweenDates(new Date(continuationDate),endDate);
      const newItinerary = {...selectedItinerary.itinerary};
      newItinerary.endDate = endDate.toISOString();
      const newLocations = [...newItinerary.locations,
        {
          country : props.country,
          order:selectedItinerary.itinerary.locations.length + 1,
          numberOfDays : daysBetween, 
          startDate : new Date(continuationDate).toISOString(),
          endDate : endDate.toISOString(),
          ...props.location
        }
      ];
      newItinerary.locations = newLocations;
      addToItinerary(newItinerary,newItinerary.id);
      //Set as the current itinerary
      setSelectedItinerary(newItinerary);
      props.onDismiss(false);
    }

  }

  return(
    <Modal text="" title="Edit Itinerary" additionalClassName="add-itinerary" onDismiss={props.onDismiss}>
      <div className="dialog">
        <div className="error-panel">
          <p ref={errorRef} className="error"></p>
        </div>
        <h2><strong>Start Date: </strong>{ formatIsoAsUkDate(selectedItinerary.itinerary.startDate) }</h2>
        <h2><strong>End Date: </strong>{ formatIsoAsUkDate(selectedItinerary.itinerary.endDate) }</h2>
        {
          selectedItinerary.itinerary.locations.length > 0
          ? <div className="itinerary-items">
              <strong className="itinerary-item">
                <p></p>
                <p>Location</p>
                <p>Days</p>
                <p>Start</p>
                <p>End</p>
              </strong>
              <div className="rows">
                {
                  selectedItinerary.itinerary.locations.map((location,idx) => (
                    <div key={location.id} className="itinerary-item">
                      <p>{idx+1}.</p>
                      <p>{location.name}</p>
                      <p>{location.numberOfDays} days</p>
                      <p>{formatIsoAsUkDate(location.startDate)}</p>
                      <p>{formatIsoAsUkDate(location.endDate)}</p>
                    </div>
                  ))
                }
              </div>

            </div>
          : <></>
        }
        <div>
            {
              existsInItinerary
              ? <>
                <div className="error"><p>This location is already in your itinerary.</p></div>
              </>
              : <>
                <p>Add <strong>{props.location.name}</strong> to your itinerary:</p>
                <div className="form-group">
                  <label htmlFor="itinerary-start-date">Start Date:</label>
                  <strong><p>{ formatIsoAsUkDate(continuationDate) }</p></strong>
                </div>
                <div className="form-group">
                  <label htmlFor="itinerary-end-date">End Date:</label>
                  <DatePicker onDateSelected={setEndDate} id="itinerary-end-date" date={ addDaysToDate(selectedItinerary.itinerary.endDate,1)} />
                </div>
              </>
            }
        </div>
        {
          existsInItinerary
          ? <button onClick={(evt) => props.onDismiss()}>Close</button>
          : <div className="button-bar-horizontal"> 
            <button onClick={(evt) => handleAddToItinerary(evt)}>Add Location</button>
            <button onClick={(evt) => props.onDismiss()}>Close</button>
          </div>
            
        }
      </div>
    </Modal>
  );
}

export default EditItineraryDialog;