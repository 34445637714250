import { getCountries, getLocations } from "../network/apiActions";

const storeCountries = (countryMap) => {
  const expiry = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  const countryData = {
    countryMap,
    expiry
  }
  localStorage.setItem("countries",JSON.stringify(countryData));
}

//Overwrite all of the locaiton data with the new data
const storeAllLocationData = (locationData,location,country) => {
  const locations = {...locationData};
  const newLocations = locations[country];
  newLocations[location.name] = location;
  locations[country] = newLocations;
  localStorage.setItem("locations",JSON.stringify(locations));
}

const retrieveCountryData = async () => {
    //Make a call to the API
    let countryData = {}
    try {
      countryData = await getCountries();
    } catch(err) {
      throw(err);
    }
    
    const countryMap = countryData.reduce((acc, itm) => {
      // Create a new property with the name from itm.name
      acc[itm.name] = {
        id : itm.id,
        description : itm.description,
        image : itm.image,
        names : itm.names,
        capital : itm.capital,
        attractions : itm.attractions,
      };
      return acc;
    }, {});
    //Store in browser storage for caching
    storeCountries(countryMap);
    return countryMap;
}

const loadCountries = async () => {
  return new Promise( async (resolve,reject) => {
    //Try to retrieve from local storage
    const localCountryData =  JSON.parse(localStorage.getItem("countries"));
    let countryMap = {};
    if(localCountryData) {
      //Check expiry
      if(new Date() > new Date(localCountryData.expiry)) {
        countryMap = await retrieveCountryData();
      } else {
        countryMap = localCountryData['countryMap'];
      }
    } else {
      try{
        countryMap = await retrieveCountryData();
      } catch(err) {
        return reject(err);
      }
    }

    return resolve(countryMap);
  });
}

const retrieveLocationsForCountry = async (countryName, locationMap) => {
  const countryList = Object.keys(locationMap);
  let reducedLocationData = {};
  if(!countryList.includes(countryName)){
    //If the country is not there we make the call to the api to get the locations
    try {
      const locationData = await getLocations(countryName);
      reducedLocationData = await locationData.reduce((acc, itm) => {
        // Create a new property with the name from itm.name
        acc[itm.name] = {
          id : itm.id,
          description : itm.description,
          dateModified : itm.date_modified,
          dateAdded : itm.date_added,
          name : itm.name,
          geoLocation : itm.geo_location,
          attractions : itm.attractions,
        };
        return acc;
      }, {});
    } catch(err) {
      throw(err);
    }
  }
  return reducedLocationData;
}

const storeLocationsForCountry = async (countryName,countryLocationData) => {
  const expiry = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
  countryLocationData[countryName]['expiry'] = expiry;
  localStorage.setItem("locations",JSON.stringify(countryLocationData));
}

const getDataFromApi = async (countryName,locationMap) => {
  let updatedLocationMap = {...locationMap};

  try {
    const locationsForCountry = await retrieveLocationsForCountry(countryName,locationMap);
    updatedLocationMap[`${countryName}`] = locationsForCountry;
    storeLocationsForCountry(countryName,updatedLocationMap);
  } catch(err) {
    console.log("Failed to get locations",err);
  }

  return updatedLocationMap;
}

const loadLocationsForCountry = async (countryName) => {
  return new Promise( async (resolve,reject) => {
    //Try to retrieve from local storage
    const dataFromStorage = localStorage.getItem("locations");
    const localLocationsData =  await JSON.parse(dataFromStorage);
    
    let locationMap = {};
    if(localLocationsData) {
      locationMap = localLocationsData;
      //See if the country exists in the location data
      const countryList = Object.keys(locationMap);
      //EXISTS IN LOCAL STORAGE
      if (countryList.includes(countryName) ) {
        //Check expiry
        const expiryDate = locationMap[countryName].expiry;
        if(new Date() > new Date(expiryDate)){
          //Get data from the api
          //Delete the country entry before making the call
          delete locationMap[countryName];
          locationMap = getDataFromApi(countryName,locationMap);
        }
      } else {
        //Get data from the api
        locationMap = getDataFromApi(countryName,locationMap);
      }
    } else {
      //Create the location data cache in local storage (empty object)
      localStorage.setItem("locations",JSON.stringify({}));
      locationMap = getDataFromApi(countryName,locationMap);
    }

    return resolve(locationMap);
  });
}

const addToItinerary = (itinerary,itineraryId) => {
  //Retrieve itineraries from storage and save the new one
  const dataFromStorage = localStorage.getItem("itineraries");
  let itineraries = [];
  if(dataFromStorage) {
    const newItineraries = JSON.parse(dataFromStorage);
    itineraries = newItineraries.filter(el => el.id !== itineraryId);
  }
  itineraries.push(itinerary);
  //Save
  localStorage.setItem("itineraries",JSON.stringify(itineraries));
  return itineraries;
}

const getItineraries = () => {
  const dataFromStorage = localStorage.getItem("itineraries");
  if(dataFromStorage) {
    return JSON.parse(dataFromStorage);
  }
  return [];
}

const deleteItineraryFromStorage = (itineraryId) => {
  const dataFromStorage = localStorage.getItem("itineraries");
  if(dataFromStorage) {
    const parsedItineraries = JSON.parse(dataFromStorage);

    const newItineraries = parsedItineraries.filter(i => i.id !== itineraryId);
    localStorage.setItem("itineraries",JSON.stringify(newItineraries));
  }
}


export {loadCountries, loadLocationsForCountry,storeAllLocationData, addToItinerary, getItineraries, deleteItineraryFromStorage};