import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useContext, useEffect, useState } from "react";
import {Context as CountryContext} from "../context/CountryContext";
import LoadingWidget from './LoadingWidget';
import locationPin from '../assets/pin_icon_blue.png';
import L from 'leaflet';
import { getLocationFromCoords } from '../network/apiActions';
import WorldMapGraphic from '../assets/map_graphic.jpg';


const ItineraryMap = (props) => {
  const [locationExists, setLocationExists]  = useState(false);
  const [locationPosition,setLocationPosition] = useState([]);
  const [loadingItinerary,setLoadingItinerary] = useState(false);
  const {state:{selectedItinerary, itineraries}} = useContext(CountryContext);

  useEffect(() => {
    if(selectedItinerary) {
      const locations = selectedItinerary.itinerary.locations;
      if(locations.length > 0) {
        setLocationPosition([locations[0].geoLocation.lat, locations[0].geoLocation.lng]);
        setLocationExists(true);
      } else {
        setLocationExists(false);
      }
      
    }
  },[selectedItinerary]);

  const handleMarkerClick = (location) => {
    //UNUSED - MIGHT FIND USE IN LATER VERSION
  }

  // Create a custom icon
  const locationIcon = L.icon({
    iconUrl: locationPin,
    iconSize: [30, 40], // Size of the icon
    iconAnchor: [15, 40], // Anchor point of the icon (left and top point, usually centered)
    popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
  });

  // Create a custom DivIcon with text overlay
const createCustomIcon = (text) => {
  return new L.DivIcon({
    //className: 'numbered-marker', // Custom class to style the icon
    html: `<div class="numbered-marker"><div class="marker-text">${text}</div></div>`, // Overlay text on the marker
  });
};

  return (
   selectedItinerary && itineraries.length > 0
   ?  <div className='country-map-panel' id={selectedItinerary.id}>
        {
          locationExists
          ?       <div className={loadingItinerary ? "country-map loading" : "country-map"}>
                    <MapContainer center={locationPosition} zoom={6} style={{ height: "100%", width: "100%" }} className={loadingItinerary ? 'loading': ''}>
                      {/* TileLayer is for the actual map tiles */}
                      <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      />
                      {
                        selectedItinerary.itinerary.locations.map((location,idx) => (
                          <Marker key={location.id} position={[location.geoLocation.lat,location.geoLocation.lng]} icon={createCustomIcon(idx+1)} eventHandlers={{ click: () => handleMarkerClick(location) }}>
                            <Popup>
                              {location.name}
                            </Popup>
                            
                          </Marker>
                        ))
                      }
                    </MapContainer>
                    <div className='map-click-message-container'>
                      <p className='map-click-message'>messages</p>
                    </div>
                  </div>
          : <div className='country-map'>
            <div className='world-map-graphic-container'>
              <img src={WorldMapGraphic} />

            </div>
          </div>
        } 
      </div>
    : <></>

  );
};

export default ItineraryMap;


