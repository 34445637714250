import { useContext } from "react";
import {Context as CountryContext} from "../context/CountryContext";
import { useNavigate } from "react-router-dom";


const CountryInfoPanel = () => {
  const {state:{selectedCountry}} = useContext(CountryContext);
  const navigate = useNavigate();

  const handleViewCountry = () => {
    navigate(`/viewcountry/${selectedCountry.names[selectedCountry.names.length - 1]}`);
  }

  return(
    <div className="country-info-panel">
      {
        selectedCountry 
          ? <div> 
              <p className="country-description">{selectedCountry.description}</p>
              <div className="capital-detail">
                <div><strong><p>Capital:</p></strong></div><div><p>{selectedCountry.capital ? selectedCountry.capital.name : "" }</p></div>
              </div>
              <div className="button-bar-horizontal">
                <button className="btn" onClick={() => handleViewCountry()}>View Country</button>
              </div>
              {
                selectedCountry.attractions
                ? <div>
                    <h2>Top Attractions</h2>
                    {
                      selectedCountry.attractions.map( (attraction,idx) => (
                        <p key={idx}><a href={`/attraction/${attraction.name}`}>{attraction.name}</a></p>
                      ))
                    }
                  </div>
                : <h2></h2>
              }
            </div>
          : <h2>Select a country from the map to get started</h2>
      }
    </div>
  );
}

export default CountryInfoPanel;