//Prompts the user if they want to remove all of their locations from the itinerary and saves the result
import Modal from "./Modal";
import {Context as CountryContext} from "../../context/CountryContext";
import { useContext } from "react";
import { addToItinerary, getItineraries } from "../../localstorage/store";

const ClearLocationsDialog = (props) => {
  const {state:{selectedItinerary},setSelectedItinerary, setItineraries} = useContext(CountryContext);

  const handleClearLocations = () => {
    const newItinerary = { ...selectedItinerary.itinerary };
    newItinerary.locations = [];

    setSelectedItinerary(newItinerary);
    // //Save to storage and update
    addToItinerary(newItinerary, newItinerary.id);
    // //Set as the current itinerary
    const newItineraries = getItineraries();
    setItineraries(newItineraries);
    props.onDismiss();
  }

  return(
    <Modal text="" title="Clear All Locations" onDismiss={props.onDismiss} >
      <p>Do you want to clear all locations for this itinerary?</p>
      <div className="button-bar-horizontal">
        <button className="btn" onClick={() => handleClearLocations()}>Yes</button>
        <button className="No" onClick={() => props.onDismiss()} >No</button>
      </div>
    </Modal>
  );
}

export default ClearLocationsDialog;