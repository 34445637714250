import { Routes, Route} from "react-router-dom";
import Home from './screens/Home';
import CountryScreen from './screens/CountryScreen';
import Layout from './layout/Layout';
import ItinerariesScreen from "./screens/ItinerariesScreen";


const Main = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                </Route>
                <Route path="/viewcountry/:country" element={<CountryScreen />}>
                    <Route index element={<Home />} />
                </Route>
                <Route path="/itineraries" element={<ItinerariesScreen />}>
                    <Route index element={<Home />} />
                </Route>
            </Routes>
        </>
    );
}


export default Main;