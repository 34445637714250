import { useState, useContext } from "react";
import CreateBlankItineraryDialog from "../components/dialogs/CreateBlankItineraryDialog";
import { Context as CountryContext } from "../context/CountryContext";
import ItinerarySelect from "../components/ItinerarySelect";
import CountryTitleBanner from "../components/CountryTitleBanner";
import ItineraryMap from "../components/ItineraryMap";
import ItineraryItemsPanel from "../components/ItineraryItemsPanel";
import Footer from "../layout/Footer";
import { formatIsoAsUkDate } from "../helpers/helpers";
import EditStartDateDialog from "../components/dialogs/EditStartDateDialog";

const ItinerariesScreen = () => {
  const [showCreateItinerary,setShowCreateItinerary] = useState(false)
  const [showSetStartDate, setShowSetStartDate] = useState(false);
  const {state:{selectedItinerary,itineraries}} = useContext(CountryContext);

  return(
    <div className="content">
      {
        showCreateItinerary
        ? <CreateBlankItineraryDialog onDismiss={() => setShowCreateItinerary(false)} />
        : <></>
      }
      {
        showSetStartDate
        ? <EditStartDateDialog onDismiss={() => setShowSetStartDate(false)} />
        : <></>
      }
      <CountryTitleBanner />
        <div className="itinerary-content">
        {
          selectedItinerary && itineraries.length > 0
          ?  <div>
              <h1>{selectedItinerary.itinerary.name}</h1>
              <div className="itinerary-date-range">
                <p><strong>From:</strong> {formatIsoAsUkDate(selectedItinerary.itinerary.startDate)}</p>
                <p> - </p>
                <p><strong>To:</strong> {formatIsoAsUkDate(selectedItinerary.itinerary.endDate)}</p>
              </div>
              <button className="btn" onClick={() => setShowSetStartDate(true)} >Change Start Date</button>
            </div> 
          : <h1>No Itinerary Is Selected</h1>
        }
        <div className="itineraries-layout display-panel">
          <div className="country-map-container">
            {
              selectedItinerary && itineraries.length > 0
              ? <ItineraryMap />
              : <></>
            }
          </div>
          <div className="itinerary-detail-panel">
            <h2>My Itineraries</h2>
            <div className="itinerary-select">
              {
                selectedItinerary && itineraries.length > 0
                ? <ItinerarySelect/>
                : <></>
              }
              <button className="btn" onClick={() => setShowCreateItinerary(true) }>Create New Itinerary</button>
            </div>
            <ItineraryItemsPanel />
          </div>
        </div>
      </div>

      <Footer />
    </div>

  );

}

export default ItinerariesScreen;