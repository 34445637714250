import { useContext, useRef, useState, useEffect } from "react";
import Modal from "./Modal";
import {Context as CountryContext } from "../../context/CountryContext";
import { formatIsoAsUkDate, addDaysToDate, getDaysBetweenDates } from "../../helpers/helpers";
import { addToItinerary, getItineraries } from "../../localstorage/store";
import UpArrow from '../../assets/up_arrow.png';
import DownArrow from '../../assets/down_arrow.png';
import Delete from '../../assets/delete.png';
import ClearLocationsDialog from "./ClearLocationsDialog";

const EditItineraryOrderDialog = (props) => {
  const [showClearDialog, setShowClearDialog] = useState(false);
  const { state:{selectedItinerary}, setSelectedItinerary, setItineraries } = useContext(CountryContext);
  const errorRef = useRef(null);
  const numberOfDays = 1;

  useEffect(() => {
  },[]);

  const reOrganiseLocations = (newLocations) => {
    const reOrganizedLocations = [...newLocations];
    //reorganize the locations
    const itineraryStart = selectedItinerary.itinerary.startDate;
    let prevDate = new Date();
    for (let i=0;i<reOrganizedLocations.length;i++) {
      let startDate;
      if(i===0) {
        reOrganizedLocations[i].startDate = itineraryStart;
        //Get date object
        startDate= new Date(itineraryStart);
      } else {
        reOrganizedLocations[i].startDate = prevDate.toISOString() ;
        //Get date object
        startDate= prevDate;
      }
      //Calculate end date
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + reOrganizedLocations[i].numberOfDays);
      reOrganizedLocations[i].endDate = endDate.toISOString();
      prevDate =endDate;
      //Set the order
      reOrganizedLocations[i].order = i+1;

    }
    return reOrganizedLocations;
  }

  const moveLocationUp = (evt,idx) => {
    evt.preventDefault();
    const newLocations = [...selectedItinerary.itinerary.locations];
    const swapLocation1 = newLocations[idx-1];
    const swapLocation2 = newLocations[idx];
    newLocations[idx-1] = swapLocation2;
    newLocations[idx] = swapLocation1;
    const reOrganizedLocations = reOrganiseLocations(newLocations);
    //Update the itinerary
    const newItinerary = {...selectedItinerary};
    newItinerary.itinerary.locations = reOrganizedLocations;
    setSelectedItinerary(newItinerary.itinerary);
  }

  const moveLocationDown = (evt,idx) => {
    evt.preventDefault();
    const newLocations = [...selectedItinerary.itinerary.locations];
    const swapLocation1 = newLocations[idx+1];
    const swapLocation2 = newLocations[idx];
    newLocations[idx+1] = swapLocation2;
    newLocations[idx] = swapLocation1;
    const reOrganizedLocations = reOrganiseLocations(newLocations);
    //Update the itinerary
    const newItinerary = {...selectedItinerary};
    newItinerary.itinerary.locations = reOrganizedLocations;
    setSelectedItinerary(newItinerary.itinerary);
  }

  const deleteLocation = (evt,idx) => {
    evt.preventDefault();
    const removeElement = selectedItinerary.itinerary.locations[idx];

    const newLocations = [...selectedItinerary.itinerary.locations];
    const newLocationsFiltered = newLocations.filter((el) => el !== removeElement);
    const reOrganisedLocations = reOrganiseLocations(newLocationsFiltered);
    //Update the itinerary
    const newItinerary = {...selectedItinerary};
    //Update the end date
    if(reOrganisedLocations.length > 0) {
      newItinerary.itinerary.locations = reOrganisedLocations;
      newItinerary.itinerary.endDate = reOrganisedLocations[reOrganisedLocations.length - 1].endDate;
      setSelectedItinerary(newItinerary.itinerary);
    } else {
      newItinerary.itinerary.endDate = newItinerary.itinerary.startDate;
      //Ask to clear the itinerary as we are on the last item
      setShowClearDialog(true);
    }
  }

  //Save the itinerary in that it is updated to the itineraries list and storage
  const saveItinerary = () => {
    addToItinerary(selectedItinerary.itinerary, selectedItinerary.itinerary.id);
    //Set as the current itinerary
    const newItineraries = getItineraries();
    setItineraries(newItineraries);
    props.onDismiss();
  }


  return(
    !showClearDialog
    ? <Modal text="" title="Edit Itinerary" additionalClassName="add-itinerary" onDismiss={props.onDismiss}>
      <div className="dialog">
        <div className="error-panel">
          <p ref={errorRef} className="error"></p>
        </div>
        <h2><strong>Start Date: </strong>{ formatIsoAsUkDate(selectedItinerary.itinerary.startDate) }</h2>
        <h2><strong>End Date: </strong>{ formatIsoAsUkDate(selectedItinerary.itinerary.endDate) }</h2>
        {
          selectedItinerary.itinerary.locations.length > 0
          ? <div className="itinerary-items">
              <strong className="itinerary-item">
                <p></p>
                <p>Location</p>
                <p>Days</p>
                <p>Start</p>
                <p>End</p>
              </strong>
              <div className="rows">
                {
                  selectedItinerary.itinerary.locations.map((location,idx) => (
                    <div key={location.id} className="itinerary-item">
                      <p>{idx+1}.</p>
                      <p>{location.name}</p>
                      <p>{location.numberOfDays} days</p>
                      <p>{formatIsoAsUkDate(location.startDate)}</p>
                      <p>{formatIsoAsUkDate(location.endDate)}</p>
                      {
                        idx > 0
                        ? <div className="row-icon-container" onClick={(evt) => moveLocationUp(evt,idx) }>
                          <img src={UpArrow} alt="Up arrow icon" />
                        </div>
                        : <div className="row-icon-container"></div>
                      }
                      {
                        idx < selectedItinerary.itinerary.locations.length - 1
                        ? <div className="row-icon-container" onClick={(evt) => moveLocationDown(evt,idx) }>
                          <img src={DownArrow} alt="Down arrow icon" />
                        </div>
                        : <div className="row-icon-container"></div>
                      }
                      <div className="row-icon-container" onClick={(evt) => deleteLocation(evt,idx)}>
                        <img src={Delete} alt="Delete" />
                      </div>
                    </div>
                  ))
                }
              </div>

            </div>
          : <></>
        }
        <button onClick={(evt) => saveItinerary()}>Close</button>
      </div>
    </Modal>
    : <ClearLocationsDialog onDismiss={() => setShowClearDialog(false)} />
  );
}

export default EditItineraryOrderDialog;