import { useContext, useState, useEffect } from "react";
import {Context as CountryContext} from '../context/CountryContext';
import CreateItineraryDialog from "./dialogs/CreateItineraryDialog";
import EditItineraryDialog from "./dialogs/EditItineraryDialog";
import ItinerarySelect from "./ItinerarySelect";


const LocationInfoPanel = (props) => {
  const {state:{selectedLocation, selectedCountry, locations,selectedItinerary,itineraries},setSelectedLocation} = useContext(CountryContext);
  const [popularLocations,setPopularLocations] = useState([]);
  const [showCreateItinerary,setShowCreateItinerary] = useState(false);
  const [showEditItinerary,setShowEditItinerary] = useState(false);

  useEffect(() => {
    loadPopularLocations(locations);
  },[]);


  const loadPopularLocations = (locations) => {
    const countryName = props.country;
    const mapLocations = {...locations[countryName]};
    delete mapLocations.expiry;
    const locationKeys = Object.keys(mapLocations);
    let popularLocaitonsList = [];
    for (const key of locationKeys) {
      popularLocaitonsList.push(mapLocations[key]);
    }
    setPopularLocations(popularLocaitonsList);
  }

  const loadLocation = (evt,location) => {
    evt.preventDefault();
    setSelectedLocation(location);
  }

  return (
    <div className="location-info-panel">
      {
        !selectedLocation
        ? <>
          <h1>Locations</h1>
          <p><strong>Here are some popular locations for {selectedCountry.names[selectedCountry.names.length-1]}. 
            Click to find out more.</strong></p>
          {
            popularLocations.map((location) => (
              <div key={location.id}><a href='' onClick={(evt) => loadLocation(evt,location)}>{location.name}</a></div>
            ))
          }
        </>
        : <>
          {
            showCreateItinerary
            ? <CreateItineraryDialog country={props.country} onDismiss={() => setShowCreateItinerary(false)} location={selectedLocation} />
            : <></>
          }
          {
            showEditItinerary
            ? <EditItineraryDialog country={props.country} onDismiss={() => setShowEditItinerary(false)} location={selectedLocation} />
            : <></>
          }
          <h1 className="alt-display">{selectedLocation.name}</h1>
          <div className="itinerary-options">
            <p><strong>Select Itinerary:</strong></p>
            {
              selectedItinerary  && itineraries.length > 0
              ? <div>
                  <ItinerarySelect />
                  <button className="btn" onClick={() => setShowEditItinerary(true)}>Add to Itinerary</button>
                </div>
              : <div>
                <button className="btn" onClick={() => setShowCreateItinerary(true)}>Add to Itinerary</button>
              </div>
            }
            
            
          </div>
          <div className="location-scroll">
            <h2>{selectedLocation.name}</h2>
            <p>{selectedLocation.description}</p>
            <h2>Attractions</h2>
            <p><strong>These are the attractions that are nearby to {selectedLocation.name}.</strong></p>
            {
              selectedLocation.attractions.map((attraction) => (
                <div key={attraction.id}>
                  <p><strong>{attraction.name}</strong></p>
                  <p>{attraction.description}</p>
                </div>
              ))
            }
          </div>
        </>
          
      }
    </div>
  );
}

export default LocationInfoPanel;