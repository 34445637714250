import { useState, useRef, useContext } from "react";
import Modal from "./Modal";
import DatePicker from "../DatePicker";
import { Context as CountryContext} from "../../context/CountryContext";
import { addToItinerary } from "../../localstorage/store";
import { v4 as uuidv4 } from 'uuid';



const CreateItineraryDialog = (props) => {
  const [startDate,setStartDate] = useState(null);
  const [endDate,setEndDate] = useState(null);
  const [itineraryName, setItineraryName] = useState(null);
  const [numberOfDays,setNumberOfDays] = useState(1);
  const errorRef = useRef(null);
  const {setSelectedItinerary,setItineraries} = useContext(CountryContext);

  function getDaysBetweenDates(startDate, endDate) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const timeDifference = endDate.getTime() - startDate.getTime();
    const daysDifference = Math.round(timeDifference / millisecondsPerDay);
    return daysDifference;
  }

  const validateFormData = (startDate,endDate,itineraryName) => {
    const errorDisplay = errorRef.current;

    //Check all fields have values
    if(startDate && endDate && itineraryName) {
      //Check that the start date is before the enddate
      if(startDate < endDate) {
        //Check that the itinerary name has a value
        if(itineraryName.trim().length > 0) {
          //Check number of days fits in the date range
          const daysBetween = getDaysBetweenDates(startDate,endDate);
          if(daysBetween >= numberOfDays) {
            return true;
          } else {
            errorDisplay.innerHTML = "The number of days is more than the number of days left in the itinerary.";
          }
        } else {
          errorDisplay.innerHTML = "Please enter a value for the itinerary name.";
        }
      } else {
        errorDisplay.innerHTML = "The start date must be before the end date.";
      }
    } else {
      errorDisplay.innerHTML = "Name, start date and end date need to be entered.";
    }

    return false;
  } 

  const handleCreateItinerary = (evt) => {
    evt.preventDefault();
    if(validateFormData(startDate,endDate,itineraryName)) {
      const daysBetween = getDaysBetweenDates(startDate,endDate);
      
      const uuid = uuidv4();
      const itineraryObject = {
        id : uuid,
        name : itineraryName,
        startDate : startDate.toISOString(),
        endDate : endDate.toISOString(),
        locations : [{ country:props.country, order:1,numberOfDays : daysBetween, startDate : startDate.toISOString(),endDate : endDate.toISOString(),...props.location}],
      }
      addToItinerary(itineraryObject,uuid);
      //Set as the current itinerary
      setSelectedItinerary(itineraryObject);
      setItineraries([itineraryObject]);
      props.onDismiss(false);
    }
    
  }

  const handleNameInput = (evt) => {
    setItineraryName(evt.currentTarget.value);
  } 

  //NOT IN USE
  // const handleItineraryNumberOfDays = (evt) => {
  //   evt.preventDefault();
  //   setNumberOfDays(parseInt(evt.currentTarget.value));
  // }

  return (
    <Modal text="" title="Create New Itinerary" additionalClassName="add-itinerary" onDismiss={props.onDismiss}>
      <div className="dialog">
        <div className="error-panel">
          <p ref={errorRef} className="error"></p>
        </div>
        <div className="form-group">
          <label htmlFor="itinerary-name-input">Name of Itinerary:</label>
          <input onInput={(evt) => handleNameInput(evt)} id="itinerary-name-input" type="text"></input>
        </div>
        <p><strong>Add {props.location.name} to your itinerary.</strong></p>
        <div className="form-group">
          <label htmlFor="itinerary-start-date">Start Date:</label>
          <DatePicker onDateSelected={setStartDate} id="itinerary-start-date" />
        </div>
        <div className="form-group">
          <label htmlFor="itinerary-end-date">End Date:</label>
          <DatePicker onDateSelected={setEndDate} id="itinerary-end-date"  />
        </div>
      </div>
      <button onClick={(evt) => handleCreateItinerary(evt)}>Create</button>
    </Modal>
  )
}

export default CreateItineraryDialog;
