
const formatIsoAsUkDate = (dateStr) => {
  const date = new Date(dateStr);

  return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
}

const addDaysToDate = (datestr,days) => {
  const date = new Date(datestr);
  const newDay = date.getDate() + days;
  date.setDate(newDay);
  return date.toISOString();
}

const getDaysBetweenDates = (startDate, endDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const timeDifference = endDate.getTime() - startDate.getTime();
  const daysDifference = Math.round(timeDifference / millisecondsPerDay);
  return daysDifference;
}

function zeroPad(num, places) {
  return String(num).padStart(places, '0');
}


const reOrganiseLocations = (newLocations, newStartDate) => {
  const reOrganizedLocations = [...newLocations];
  //reorganize the locations
  const itineraryStart = newStartDate;
  let prevDate = new Date();
  for (let i=0;i<reOrganizedLocations.length;i++) {
    let startDate;
    if(i===0) {
      reOrganizedLocations[i].startDate = itineraryStart;
      //Get date object
      startDate= new Date(itineraryStart);
    } else {
      reOrganizedLocations[i].startDate = prevDate.toISOString() ;
      //Get date object
      startDate= prevDate;
    }
    //Calculate end date
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + reOrganizedLocations[i].numberOfDays);
    reOrganizedLocations[i].endDate = endDate.toISOString();
    prevDate =endDate;
    //Set the order
    reOrganizedLocations[i].order = i+1;

  }
  return reOrganizedLocations;
}

const compareDates = (date1,date2) => {
  if(date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear() ) {
    return true;
  }
  return false;
} 



export {formatIsoAsUkDate, addDaysToDate, getDaysBetweenDates,zeroPad, reOrganiseLocations, compareDates};