import WorldMap from "../components/WorldMap";
import { useEffect, useContext, useState } from "react";
import { loadCountries } from "../localstorage/store";
import {Context as CountryContext} from "../context/CountryContext";
import CountryInfoPanel from "../components/CountryInfoPanel";
import LoadingWidget from "../components/LoadingWidget";
import NetworkErrorWidget from "../components/NetworkErrorWidget";
import CountryTitleBanner from "../components/CountryTitleBanner";
import ItinerarySelect from "../components/ItinerarySelect";
import Footer from "../layout/Footer";
import { useNavigate } from "react-router-dom";


const Home = () => {
  const {state:{itineraries,selectedItinerary},setCountries,setSelectedCountry,setSelectedItinerary} = useContext(CountryContext);
  const [loading,setLoading] = useState(true);
  const [networkError, setNetworkError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadCountries().then((countryMap) => {
      setCountries(countryMap);
      setLoading(false);
    }).catch(err => {
      setNetworkError(true);
    });
  },[]) 

  const handleClickItineraries = () => {
    setSelectedCountry(null);
    navigate('/itineraries');
  } 
  
  return(
    <div className="content">
      <CountryTitleBanner />
      <div className="home-itinerary-select">
        <h2>My Itineraries:</h2>
        {
          selectedItinerary  && itineraries.length > 0
          ? <ItinerarySelect />
          : <></>
        }

        {
          itineraries.length > 0
          ? <button onClick={() => handleClickItineraries()} className="btn">Manage Itineraries</button>
          : <></>
        }
      </div>
      {
        networkError
        ? <NetworkErrorWidget />
        : <>
          {
            loading
            ?<LoadingWidget />
            :<CountryInfoPanel />
          }
          <WorldMap />
        </>
      }
      <Footer />
    </div>
  );
}

export default Home;