import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useContext, useEffect, useState } from "react";
import {Context as CountryContext} from "../context/CountryContext";
import LoadingWidget from './LoadingWidget';
import L from 'leaflet';
import capitalPin from '../assets/pin_icon_red.png';
import popLocationPin from '../assets/pin_icon_blue.png';
import selectedLocationPin from '../assets/pin_icon_yellow.png';
import { loadLocationsForCountry,storeAllLocationData } from '../localstorage/store';
import { calculateHaversineDistance, getCountryFromCoordinates,getClosestLocation } from '../data/geofunctions';
import { getLocationFromCoords } from '../network/apiActions';

//TODO: 
// On loading the location panel needs to display a list of the popular locations
// Update info in the location panel is the next step

const CountryMap = (props) => {
  const distanceThreshold = 10;
  const { state:{selectedCountry,selectedLocation,countryMapMessage,locations},
    setLocations,setSelectedLocation,setCountryMapMessage,addLocation} = useContext(CountryContext);
  const [capitalPosition, setCapitalPosition] = useState(null);
  const [capitalLocationError, setCaptialLocationError] = useState(false);
  const [loadingLocation,setLoadingLocation] = useState(false);
  const [popularLocations,setPopularLocations] = useState([]);


  // Create a custom icon
  const capitalIcon = L.icon({
    iconUrl: capitalPin,
    iconSize: [30, 40], // Size of the icon
    iconAnchor: [15, 40], // Anchor point of the icon (left and top point, usually centered)
    popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
  });

  // Create a custom icon
  const popLocationIcon = L.icon({
    iconUrl: popLocationPin,
    iconSize: [30, 40], // Size of the icon
    iconAnchor: [15, 40], // Anchor point of the icon (left and top point, usually centered)
    popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
  });

  // Create marker icon for selected locations
  const selectedLocationIcon = L.icon({
    iconUrl: selectedLocationPin,
    iconSize: [30, 40], // Size of the icon
    iconAnchor: [15, 40], // Anchor point of the icon (left and top point, usually centered)
    popupAnchor: [0, -40], // Point from which the popup should open relative to the iconAnchor
  });

  useEffect(() => {
    if(selectedCountry.capital) {
      const capitalLocaitonPoistion =  [selectedCountry.capital.location.lat, selectedCountry.capital.location.lng];
      setCapitalPosition(capitalLocaitonPoistion);
      loadPopularLocations(locations);
    } else {
      setCaptialLocationError(true);
    }

  },[locations]);


  const loadPopularLocations = (locations) => {
    const countryName = props.country;
    const mapLocations = {...locations[countryName]};
    //Set the selected location to the capital if there isn't one selected
    if(!selectedLocation) {
      setSelectedLocation(mapLocations[selectedCountry.capital.name]);
    }
    delete mapLocations[selectedCountry.capital.name];
    delete mapLocations.expiry;
    const locationKeys = Object.keys(mapLocations);
    let popularLocaitonsList = [];
    for (const key of locationKeys) {
      popularLocaitonsList.push(mapLocations[key]);
    }
    setPopularLocations(popularLocaitonsList);
  }
  // const mapLocaitonMarkers = (locations) => {
  //   let locationMarkers = [];
  //   for (const location of locations) {
      
  //   }
  // }


  


  // Component to handle map click events
  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        if(!loadingLocation) {
          const { lat, lng } = e.latlng;
          // Find the location in the stored locations
          const clickedCountry = getCountryFromCoordinates(lat,lng);
          if(clickedCountry !== selectedCountry.names[selectedCountry.names.length-1]) {
            setCountryMapMessage("Location is not within country border");
          } else {
            //Find the location in locations
            const locationList = Object.keys(locations[clickedCountry]).filter(e => e !== 'expiry');
            //Create object to hold the location with the distance
            let nearbyLocations = {};
            for (const locationName of locationList) {
              const locationCoords = locations[clickedCountry][locationName].geoLocation;
              const distanceInKm = calculateHaversineDistance(lat,lng,locationCoords.lat,locationCoords.lng) / 1000;
              //Add the location to nearby if within distance threshold
              if(distanceInKm < distanceThreshold) {
                nearbyLocations[locationName] = distanceInKm;
              }
            }
            //Sort the locations by distance
            const closest = getClosestLocation(nearbyLocations);
            if(closest) {
              setSelectedLocation(locations[props.country][closest]);
              setCountryMapMessage(locations[props.country][closest].name);
            } else {
              //If the location does not exist, request it from the API
              //Set loadingLocation before making call
              setLoadingLocation(true);
              getLocationFromCoords(lat,lng).then( async (newLocationData) => {
                delete newLocationData.country;
                //Rename keys for JavaScript camelcase
                const newLocationRenamedKeys = {...newLocationData,geoLocation:newLocationData.geo_location,
                  dateModified:newLocationData.date_modified,dateAdded:newLocationData.date_modified};
                delete newLocationRenamedKeys.date_added;
                delete newLocationRenamedKeys.date_modified;
                delete newLocationRenamedKeys.geo_location;
                addLocation(newLocationRenamedKeys,clickedCountry);
                storeAllLocationData(locations,newLocationRenamedKeys,clickedCountry);
                setSelectedLocation(newLocationRenamedKeys);
                setCountryMapMessage(newLocationRenamedKeys.name);
                setLoadingLocation(false);
              }).catch(err => {
                setCountryMapMessage("Unable to find a matching locaiton");
                setLoadingLocation(false);
              });
            }
          }
        }
      },
    });
    return null;
  };

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  }

  return (
    <div className='country-map-panel'>
      {
        !capitalLocationError
        ? <>
          {
            capitalPosition
            ? <div className={loadingLocation ? "country-map loading" : "country-map"}>
              <MapContainer center={capitalPosition} zoom={6} style={{ height: "100%", width: "100%" }} className={loadingLocation ? 'loading': ''}>
                {/* TileLayer is for the actual map tiles */}
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {/* Marker for the capital */}
                <Marker position={capitalPosition} icon={capitalIcon} eventHandlers={{ click: () => handleMarkerClick(locations[props.country][selectedCountry.capital.name]) }}>
                  <Popup>
                    {selectedCountry.capital.name} {/* Display capital name */}
                  </Popup>
                </Marker>
                {/* Map markers to locations */}
                {
                  popularLocations.map( (location,idx) => (
                    <Marker key={location.id} position={[location.geoLocation.lat,location.geoLocation.lng]} icon={popLocationIcon} eventHandlers={{ click: () => handleMarkerClick(location) }}>
                      <Popup>
                        {location.name}
                      </Popup>
                    </Marker>
                  ))
                }
                {/* Add a marker for the selected location if it exists  */}
                {
                  selectedLocation
                  ? <Marker position={[selectedLocation.geoLocation.lat,selectedLocation.geoLocation.lng]} icon={selectedLocationIcon}>
                    <Popup>
                      {selectedLocation.name} {/* Display capital name */}
                    </Popup>
                  </Marker>
                  : <></>
                }
                {/* Handle map click events */}
                <MapClickHandler />
              </MapContainer>
              <div className='map-click-message-container'>
                <p className='map-click-message'>{countryMapMessage}</p>
              </div>
              {
                loadingLocation
                ? <div className='loading-container'>
                  <LoadingWidget />
                </div> 
                : <></>
              }
              
            </div>
            

            : <LoadingWidget />
          }
        </>
        : <>
          <h2>There is an error loading the map.</h2>
        </>
      }
      

    </div>
  );
};

export default CountryMap;
