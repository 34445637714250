import { useContext } from "react";
import { Context as CountryContext } from "../context/CountryContext";
import TitleImage from '../assets/title_image.jpg';
import ItineraryBannerImage from '../assets/itinerary_banner_image.jpg';
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "../assets/home4.png";

const CountryTitleBanner = () => {
  const {state:{selectedCountry}} = useContext(CountryContext);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <div className="country-image-banner"> 
      {
        selectedCountry
        ? <>
          {
          selectedCountry.image
          ? <>
              <div className="country-image-container">
                <img src={selectedCountry.image}></img>
                <div className="home-button" onClick={() => navigate('/')}>
                  <img src={HomeIcon} alt="Home" />
                </div>
              </div>
              <h2>{selectedCountry.names[selectedCountry.names.length - 1]}</h2>
              <h2 className="under-country-name" >{selectedCountry.names[selectedCountry.names.length - 1]}</h2>
            </>
          : <></>
          }
        </>
        : <>
          {
            location.pathname == "/"
            ? <>
              <div className="country-image-container">
                <img src={TitleImage}></img>
              </div>
              <h2>Travel Jaunt</h2>
              <h2 className="under-country-name" >Travel Jaunt</h2>
          </>
          : <>
            <div className="country-image-container">
              <img src={ItineraryBannerImage}></img>
              <div className="home-button" onClick={() => navigate('/')}>
                  <img src={HomeIcon} alt="Home" />
              </div>
            </div>
            <h2>My Itineraries</h2>
            <h2 className="under-country-name" >My Itineraries</h2>
          
          </>
          }
        </>
      }
      </div>
    </>
  );
}

export default CountryTitleBanner;